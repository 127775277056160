import { useState, useEffect } from 'react'

export const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false)
  useEffect(() => {
    const media = window?.matchMedia?.(query)
    if (!media) return
    setMatches(media.matches)

    const handler = ({ matches }) => setMatches(matches)

    try {
      media.addEventListener('change', handler)
      return () => media.removeEventListener('change', handler)
    } catch (e) {
      // For backwards compatibility
      // eslint-disable-next-line max-len
      // see: https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/addListener
      if (e instanceof TypeError && 'addListener' in media) {
        media.addListener(handler)
        return () => media.removeListener(handler)
      }

      throw e
    }
  }, [])

  return matches
}
