import React, { FC } from 'react'

import styles from './NicotineWarning.module.scss'

export type Props = {
  desktopImage: {
    description: string
    url: string
  }
  mobileImage: {
    url: string
  }
}

export const NicotineWarningCA: FC<Props> = ({ desktopImage, mobileImage }) => (
  <div
    id='nicotineWarning'
    className={`${styles.relative} nicotine-warning nicotine-warning--canada`}
  >
    <picture>
      <source media='(orientation: portrait)' srcSet={mobileImage.url} />

      <img
        alt={desktopImage.description}
        className='nicotine-warning__image'
        src={desktopImage.url}
      />
    </picture>
  </div>
)
