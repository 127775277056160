/* eslint-disable viewlio/no-a-component */
import React, { AnchorHTMLAttributes, DetailedHTMLProps } from 'react'

import { useRouter } from 'next/router'

import { getViewlioPath } from 'utils/routing/getViewlioPath'

type A = DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement>
type Props = {
  /**
   * The Path or URL of the Anchor
   */
  href?: string

  /**
   * If provided, will use locale instead of the current user's locale
   */
  locale?: string

  /**
   * Override the <a> with your own
   */
  overrideAnchor?: boolean
} & A

const getChild = (children: React.ReactNode): any => {
  let child: any
  if (['development', 'ci', 'test'].includes(process.env.NODE_ENV)) {
    try {
      child = React.Children.only(children)
    } catch (err) {
      throw new Error(
        'Multiple children were passed to <LocalizedLink> but only one child is supported' +
          (typeof window !== 'undefined'
            ? ' \nOpen your browser\'s console to view the Component stack trace.'
            : ''),
      )
    }
  } else {
    child = React.Children.only(children)
  }
  return child
}

export const LocalizedLink: React.FC<Props> =
  ({ children, href, locale, overrideAnchor, ...props }) => {
    const router = useRouter()
    const curLocale = locale ?? router?.locale
    const localizedUrl = getViewlioPath(curLocale, href) ?? href

    if (overrideAnchor) {
      const child = getChild(children)
      return React.cloneElement(child, { href: localizedUrl })
    } else {
      return (
        <a href={localizedUrl} {...props} >
          {children}
        </a>
      )
    }
  }
