import React, { FC } from 'react'

import { ContentWrapper } from '@juullabs/react-components'
import { PrimaryNotification as PrimaryNotificationType } from '@viewlio/types/src/contentful'

import { FlashMessages } from 'components/common/FlashMessages'
import { Snackbar } from 'components/common/Snackbar'

import styles from './Subheader.module.scss'
import { SubheaderItems } from './SubheaderItems'

type Props = {
  contentfulEntries?: Array<PrimaryNotificationType>
  hideHeaderItems?: boolean
}

export const Subheader: FC<Props> = ({
  contentfulEntries,
  hideHeaderItems = false,
}) => {
  const subHeaderItems =
    <SubheaderItems contentfulEntries={contentfulEntries} />

  return (
    <>
      {!hideHeaderItems && (
        <div className={styles.headerItemsWrapper}>
          {subHeaderItems}
        </div>
      )}
      <ContentWrapper className={styles.snackbarWrapper}>
        <Snackbar/>
      </ContentWrapper>
      <div className={styles.flashMessagesWrapper}>
        <FlashMessages/>
      </div>
    </>
  )
}
