import React, { FC } from 'react'

import { ImageSectionEntry } from '@viewlio/types/src/contentful'

import { useGlobalStore } from 'stores'
import { withStoreCustomization } from 'utils/hocs/withStoreCustomization'

import { getProps } from './NicotineWarning.serializer'
import { NicotineWarningCA } from './NicotineWarningCA'
import { NicotineWarningUS } from './NicotineWarningUS'

type Props = {
  contentfulEntry?: ImageSectionEntry
}

const NicotineWarningBase = withStoreCustomization({
  'juul-ca': NicotineWarningCA,
  'juul-us': NicotineWarningUS,
})

export const NicotineWarning: FC<Props> = ({ contentfulEntry }) => {
  const { juulioStore } = useGlobalStore()

  if (!juulioStore?.showNicotineWarning) {
    return null
  }

  return (
    <NicotineWarningBase {...getProps(contentfulEntry)} />
  )
}
