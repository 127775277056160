import React, { FC } from 'react'

import { FlashMessage as FlashMessageType } from '@viewlio/types/src'
import cx from 'classnames'

import { TranslatedMessage } from 'components/common/TranslatedMessage'

import styles from './FlashMessages.module.scss'

export const FlashMessage: FC<FlashMessageType> = ({
  content,
  translationId,
  type,
}) => {
  const messageClass = cx(styles.message, 'text--body', {
    [styles.error]: type === 'error',
    [styles.success]: type === 'success',
  })

  return (
    <div className={messageClass}>
      {content}
      {translationId && <TranslatedMessage id={translationId} />}
    </div>
  )
}
