import React, { ReactNode } from 'react'

import { Tooltip } from '@juullabs/react-components'

import { bugsnagClient } from 'lib/bugsnag'

import styles from './TemporaryLogoIconErrorBoundary.module.scss'

type Props = {
  children: ReactNode
}
type State = {
  hasError: boolean
}

export class TemporaryLogoIconErrorBoundary extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    bugsnagClient?.notify(error, (event) => {
      event.addMetadata('Logo Icon Bug Info', errorInfo)
    })
  }

  render() {
    if (this.state.hasError) {
      const message = 'Logo failed to load. Please try refreshing the page'
      return (
        <Tooltip
          className={styles.tooltip}
          ariaLabel={message}
          content={message}
          placement='bottom'
        >
          <span className={styles.infoIcon}>!</span>
        </Tooltip>
      )
    }

    return this.props.children
  }
}
