import React, { FC } from 'react'

import { PrimaryNotification as PrimaryNotificationType } from '@viewlio/types/src/contentful'

import { Entry } from 'components/contentful/Entry'

import { getProps } from './Subheader.serializer'

type Props = {
  contentfulEntries?: Array<PrimaryNotificationType>
}

export const SubheaderItems: FC<Props> = ({
  contentfulEntries,
}) => {
  const { headerItems } = getProps(contentfulEntries)

  return (
    <>
      {headerItems?.map(headerItem => (
        <Entry key={headerItem.id} {...headerItem} />
      ))}
    </>
  )
}
