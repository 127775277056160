import React from 'react'

import styles from './NicotineWarning.module.scss'

export const NicotineWarningUS = () => (
  <div id='nicotineWarning' className={`${styles.relative} nicotine-warning`}>
    WARNING: This product contains nicotine.&nbsp;
    <br className='show-tablet' />
    Nicotine is an addictive chemical.
  </div>
)
