import {
  PrimaryNotification,
} from '@viewlio/types/src/contentful'

import { formatEntry } from 'lib/contentful/formatEntry'

export const getProps = (entries: PrimaryNotification[]) => {
  if (!entries) return {}
  return {
    headerItems: entries.map(entry => formatEntry(entry)),
  }
}
