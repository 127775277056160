import React, { useEffect, useRef, useState, forwardRef } from 'react';
import classNames from 'classnames';
import { SnackbarPosition } from './SnackbarContainer';

export interface SnackbarItemProps {
  /**
   * The children to be rendered.
   */
  children?: React.ReactNode;

  /**
   * The class name that gets applied to the root element.
   */
  className?: string;

  /**
   * The duration, in milliseconds, before the item will disappear.
   */
  duration?: number;

  /**
   * The unique ID associated with this item. Passing the same ID will allow
   * only one instance of that item to be shown; all other calls to add that
   * item will update the existing item instead.
   */
  id?: string;

  /**
   * A callback that gets called when the snackbar item unmounts.
   * @param id The ID of the snackbar item that is being unmounted.
   */
  onExit?(id: string): void;

  /**
   * The position where the snackbar will be shown. Either `SnackbarPosition.Top`,
   * `SnackbarPosition.Bottom`, or `SnackbarPosition.AddedToCart`.
   */
  position?: SnackbarPosition;

  /**
   * The class to apply to the root element each time the snackbar item
   * gets updated.
   */
  updateAnimationClassName?: string;
}

export const SnackbarItem = forwardRef<HTMLDivElement, SnackbarItemProps>(
  (
    { children, className, duration = 3000, onExit, updateAnimationClassName },
    ref
  ) => {
    const isInitialMount = useRef(true);
    const [isAnimating, setIsAnimating] = useState(false);
    const [isHovering, setIsHovering] = useState(false);

    useEffect(() => {
      if (duration && !isHovering) {
        const timeout = setTimeout(onExit, duration);
        return () => clearTimeout(timeout);
      }
    }, [duration, isHovering, onExit]);

    // Play an animation each time the children prop is updated
    useEffect(() => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
        return;
      }

      if (!updateAnimationClassName) return;

      setIsAnimating(true);

      // Give the animation time to run before removing the class name.
      const timeout = setTimeout(() => setIsAnimating(false), 1000);

      return () => {
        clearTimeout(timeout);
      };
    }, [children, updateAnimationClassName]);

    return (
      <div
        ref={ref}
        className={classNames('snackbar-item', className, {
          [updateAnimationClassName]: isAnimating,
        })}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {children}
      </div>
    );
  }
);
