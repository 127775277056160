import React, { FC } from 'react'

import { FormattedEntry, MarketingSection , EntryComponentsMap } from '@viewlio/types/src/contentful'
import { camelize } from 'humps'
import dynamic from 'next/dynamic'

const AccordionSection = dynamic(() =>
  import('components/contentful/AccordionSection')
    .then(mod => mod.AccordionSection))

const AnnouncementNotification = dynamic(() =>
  import('components/contentful/AnnouncementNotification')
    .then(mod => mod.AnnouncementNotification))

const Carousel = dynamic(() =>
  import('components/contentful/Carousel')
    .then(mod => mod.Carousel))

const CollectionSection = dynamic(() =>
  import('components/contentful/CollectionSection')
    .then(mod => mod.CollectionSection))

const ContentSection = dynamic(() =>
  import('components/contentful/ContentSection')
    .then(mod => mod.ContentSection))

const ContentStory = dynamic(() =>
  import('components/contentful/ContentStory')
    .then(mod => mod.ContentStory))

const FrequentlyAskedQuestions = dynamic(() =>
  import('components/contentful/FrequentlyAskedQuestions')
    .then(mod => mod.FrequentlyAskedQuestions))

const ImageSection = dynamic(() =>
  import('components/contentful/ImageSection')
    .then(mod => mod.ImageSection))

const ImageWithTextEntry = dynamic(() =>
  import('components/contentful/ImageWithTextEntry')
    .then(mod => mod.ImageWithTextEntry))

const PageHero = dynamic(() =>
  import('components/contentful/PageHero')
    .then(mod => mod.PageHero))

const PrimaryNotification = dynamic(() =>
  import('components/contentful/PrimaryNotification')
    .then(mod => mod.PrimaryNotification))

const TechFeatures = dynamic(() =>
  import('components/contentful/TechFeatures')
    .then(mod => mod.TechFeatures))

const VideoWithTextEntry = dynamic(() =>
  import('components/contentful/VideoWithTextEntry')
    .then(mod => mod.VideoWithTextEntry))

const PersonalizedEntry = dynamic(() =>
  import('components/contentful/PersonalizedEntry')
    .then(mod => mod.PersonalizedEntry))

const Testimonial = dynamic(() =>
  import('components/contentful/Testimonial')
    .then(mod => mod.Testimonial))

const VideoSection = dynamic(() =>
  import('components/contentful/VideoSection')
    .then(mod => mod.VideoSection))

const PromotionalBanner = dynamic(() =>
  import('components/contentful/PromotionalBanner')
    .then(mod => mod.PromotionalBanner))

const StickyCta = dynamic(() =>
  import('components/contentful/StickyCta')
    .then(mod => mod.StickyCta))

const PlaceholderSection = dynamic(() =>
  import('components/contentful/PlaceholderSection')
    .then(mod => mod.PlaceholderSection))

const LayeredHero = dynamic(() =>
  import('components/contentful/LayeredHero')
    .then(mod => mod.LayeredHero))

const ProductDisplay = dynamic(() =>
  import('components/contentful/ProductDisplay')
    .then(mod => mod.ProductDisplay))

const ProductListing = dynamic(() =>
  import('components/contentful/ProductListing')
    .then(mod => mod.ProductListing))

const ProductBundle = dynamic(() =>
  import('components/contentful/ProductBundle')
    .then(mod => mod.ProductBundle))

export const componentsMap: EntryComponentsMap = {
  accordion: AccordionSection,
  announcementNotification: AnnouncementNotification,
  carousel: Carousel,
  collectionSection: CollectionSection,
  contentSection: ContentSection,
  contentStory: ContentStory,
  faqSection: FrequentlyAskedQuestions,
  imageContentSection: ImageWithTextEntry,
  imageSection: ImageSection,
  layeredHero: LayeredHero,
  pageHero: PageHero,
  personalizedEntry: PersonalizedEntry,
  placeholderSection: PlaceholderSection,
  primaryNotification: PrimaryNotification,
  productBundle: ProductBundle,
  productDisplay: ProductDisplay,
  productListing: ProductListing,
  promotionalBanner: PromotionalBanner,
  stickyCta: StickyCta,
  techFeatures: TechFeatures,
  testimonial: Testimonial,
  videoContentSection: VideoWithTextEntry,
  videoSection: VideoSection,
}

export const Entry: FC<FormattedEntry<MarketingSection>> = ({
  contentType,
  layoutType,
  ...props
}) => {
  const componentName = layoutType || camelize(contentType)
  const Component = componentsMap[componentName] || (() => null)
  return (
    <Component {...props} />
  )
}
