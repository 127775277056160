import React, { FC } from 'react'

import { useUiStore } from 'stores'

import { FlashMessage } from './FlashMessage'

export const FlashMessages: FC = () => {
  const { flashMessages } = useUiStore()

  return (
    <>
      {flashMessages.map((message) => (
        <FlashMessage key={message.id} {...message} />
      ))}
    </>
  )
}
