import { ImageSectionEntry } from '@viewlio/types/src/contentful'

import { formatEntry } from 'lib/contentful/formatEntry'

import { Props } from './NicotineWarningCA'

export const getProps = (entry: ImageSectionEntry): Props | null => {
  if (!entry) return null

  const data = formatEntry(entry)

  const { mobileImage, desktopImage } = data

  return {
    desktopImage: {
      description: desktopImage?.description,
      url: desktopImage?.url,
    },
    mobileImage: {
      url: mobileImage?.url,
    },
  }
}
