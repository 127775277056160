import { useEffect, useState } from 'react'

import { JuulioOauthSessionCookie, viewlioConfig } from '@viewlio/config/src/viewlioConfig'
import cookie from 'js-cookie'

const DEFAULT_SESSION_VALUES: JuulioOauthSessionCookie = {
  isFromOauth: false,
  isOauthSignUpRequest: false,
  isRegisterAccountTabHidden: false,
}

export const useFromOauthCookie = () => {
  const oauthSessionCookieJson = cookie.get(viewlioConfig.cookies.oauthSession)

  // When using cookies to alter rendering (e.g. like we do with 'from_oauth'
  // cookie), we need to make sure that the markup on the first render is not
  // different from the markup served by NextJS on the server. Otherwise we
  // will run into hydration issues. This is why we use react-state to hold the
  // cookie state, when it alters rendering, instead of reading the cookie
  // directly. Please see the following for more info:
  //
  // https://nextjs.org/docs/messages/react-hydration-error
  const [
    oauthSession,
    setOauthSession,
  ] = useState<JuulioOauthSessionCookie>(DEFAULT_SESSION_VALUES)

  /**
   * Check for the oauth session cookie and load attributes from it.
   */
  useEffect(() => {
    if (!oauthSessionCookieJson) {
      return
    }

    try {
      const {
        from_oauth,
        hide_account_registration_tab,
        is_signup_request,
      } = JSON.parse(oauthSessionCookieJson)

      setOauthSession({
        isFromOauth: from_oauth,
        isOauthSignUpRequest: from_oauth && is_signup_request,
        isRegisterAccountTabHidden: from_oauth && hide_account_registration_tab,
      })
    } catch (error) {
      // We can assume it's an oauth session as long as the cookie is present.
      // This is the fallback to ensure that oauth sessions don't leak into
      // native e-comm login.
      setOauthSession({
        ...DEFAULT_SESSION_VALUES,
        isFromOauth: true,
      })
    }
  }, [])

  /**
   * Legacy cookie parsing: set flags based on individual cookies if the nested
   * cookie could not be parsed.
   */
  useEffect(() => {
    if (oauthSessionCookieJson) {
      return
    }

    const isFromOauth = Boolean(cookie.get(viewlioConfig.cookies.fromOauth))
    const hideAccountRegistrationTab = cookie.get(viewlioConfig.cookies.isRegisterAccountTabHidden) === 'true'
    const isSignUpRequest = cookie.get(viewlioConfig.cookies.isSignUpRequest) === 'true'

    setOauthSession({
      isFromOauth,
      isOauthSignUpRequest: isFromOauth && isSignUpRequest,
      isRegisterAccountTabHidden: isFromOauth && hideAccountRegistrationTab,
    })
  }, [])

  return oauthSession
}
